<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="E-mail"
              placeholder="jan.kowalski@przyklad.pl"
              hide-details
              class="mb-3"
            ></v-text-field>
            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Hasło"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @keyup.enter="authenticateUser"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Zapamiętaj mnie"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>
              <router-link :to="{ name:'forgotten-password' }">
                Nie pamiętam hasła
              </router-link>
            </div>
            <v-btn
              block
              color="primary"
              class="mt-6"
              :loading="loading"
              @click="authenticateUser"
            >
              Zaloguj
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
    >
      {{ snackbarContent }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiEyeOutline, mdiEyeOffOutline} from '@mdi/js'
import {mapActions, mapGetters, mapState} from 'vuex'
import AuthService from '@/services/AuthService'

export default {
  name: 'Login',
  data() {
    return {
      snackbar: false,
      snackbarContent: '',
      snackbarColor: '',
      loading: false,
      isPasswordVisible: false,
      email: '',
      user: {},
      password: '',
      rules: {
        required: value => !!value || 'Pole wymagane'
      },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    }
  },
  computed: {
    ...mapState([
      'session'
    ]),
    ...mapGetters(['username', 'currentUserId'])
  },
  async created() {
    if (this.$store.state.session.loggedIn) {
      this.$router.push('/dashboard')
    }
  },
  methods: {
    ...mapActions([
      'login',
      'logout',
      'getCategories',
      'getEmployees',
      'getSystems',
      'getStatuses',
      'getDepartments',
      'getWarrantyTypes'
    ]),
    beforeRouteEnter() {
      this.logout()
    },
    setSnackBar(snackbarColor, snackbarContent) {
      this.snackbar = true
      this.snackbarColor = snackbarColor
      this.snackbarContent = snackbarContent
    },
    authenticateUser() {
      this.loading = true
      AuthService.login({password: this.password, username: this.email}).then(async response => {
        this.setSnackBar('success', 'Zalogowano pomyślnie')
        this.user = response.data
        // console.log(typeof this.user)
        await this.login({password: this.password, username: this.email})
        // console.log(this.user.role)
        // console.log(this.user.role === 3)
        // console.log(this.user.role === '3')
        // if (Number(this.user.role) === 3) {
        //   this.$router.push('/cc')
        // } else {
          await this.getCategories()
          await this.getEmployees()
          await this.getStatuses()
          await this.getSystems()
          await this.getDepartments()
          await this.getWarrantyTypes()
          this.$router.push('/dashboard')
        // }
      }).catch(() => {
        this.setSnackBar('error', 'Dane logowania nie są poprawne. Zalogowanie nie powiodło się.')
        return {name: 'NetworkError'}
      })
        .finally(() => {
        })
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
